<template>
    <div class="box grow flex column">
        <keep-alive :include="[
          'SaleSnacks',
          'SaleAddons',
          'SaleMerch',
          'Waivers',
          'SaleLessons',
          'SaleSubscriptions',
          'Home'
        ]">
          <router-view
            class="box grow"
            :clearInputHome="clearInputHome"
            :members="members"
            :basket="basket"></router-view>
        </keep-alive>
        <el-divider class="mv-10 o-020"></el-divider>
        <app-sale-buttons
          class="box bottom"
          v-if="!['createEvent', 'saleTickets', 'saleBooks'].includes($route.name)">
        </app-sale-buttons>
        <el-divider
          class="mv-10 o-020"
          v-if="!['createEvent', 'saleTickets', 'saleBooks'].includes($route.name)">
        </el-divider>
    </div>
</template>

<script>
import AppSaleButtons from './sales/SaleButtons'

export default {
  name: 'Sale',

  props: {
    clearInputHome: {
      type: Boolean,
      default: false
    },
    members: {
      type: Array,
      default: function () {
        return []
      }
    },
    basket: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  components: {
    AppSaleButtons
  },

  data () {
    return {}
  }
}
</script>
