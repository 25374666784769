var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box grow flex column"},[_c('keep-alive',{attrs:{"include":[
      'SaleSnacks',
      'SaleAddons',
      'SaleMerch',
      'Waivers',
      'SaleLessons',
      'SaleSubscriptions',
      'Home'
    ]}},[_c('router-view',{staticClass:"box grow",attrs:{"clearInputHome":_vm.clearInputHome,"members":_vm.members,"basket":_vm.basket}})],1),_c('el-divider',{staticClass:"mv-10 o-020"}),(!['createEvent', 'saleTickets', 'saleBooks'].includes(_vm.$route.name))?_c('app-sale-buttons',{staticClass:"box bottom"}):_vm._e(),(!['createEvent', 'saleTickets', 'saleBooks'].includes(_vm.$route.name))?_c('el-divider',{staticClass:"mv-10 o-020"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }