<template>
  <div class="box flex column gaps">
      <div class="box flex gaps">
        <div class="box grow" @click="clickCurrentSession">
            <el-card class="flex center clickable">
                <h4 class="m-0">Tickets</h4>
            </el-card>
        </div>
        <div class="box grow" @click="$onCommandParams('saleAddons')">
            <el-card class="flex center clickable">
                <h4 class="m-0">Addons</h4>
            </el-card>
        </div>
        <div class="box grow" @click="$onCommandParams('saleSnacks')">
            <el-card class="flex center clickable">
                <h4 class="m-0">Snacks</h4>
            </el-card>
        </div>
        <div class="box grow" @click="$onCommandParams('saleMerch')">
            <el-card class="flex center clickable">
                <h4 class="m-0">Merch</h4>
            </el-card>
        </div>
        <div class="box grow" @click="$onCommandParams('settings')" v-if="role === 'admin'">
          <el-card class="flex center clickable">
              <h4 class="m-0 danger-text">Admin</h4>
          </el-card>
        </div>
      </div>
      <div class="box flex gaps">
        <div class="box grow" @click="$onCommandParams('waivers')">
            <el-card class="flex center clickable">
                <h4 class="m-0">Waivers</h4>
            </el-card>
        </div>
        <div class="box grow" @click="clickLessons">
            <el-card class="flex center clickable">
                <h4 class="m-0">Lessons</h4>
            </el-card>
        </div>
        <div class="box grow" @click="clickSubscriptions">
            <el-card class="flex center clickable">
                <h4 class="m-0">Subs</h4>
            </el-card>
        </div>
        <div class="box grow" @click="clickEvents">
            <el-card class="flex center clickable">
                <h4 class="m-0">Events</h4>
            </el-card>
        </div>
        <div class="box grow">
            <el-card class="flex center clickable">
                <h4 class="m-0">Schedule</h4>
            </el-card>
        </div>
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'SaleButton',

  data () {
    return {}
  },

  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    role () {
      return this.user && this.user.hasOwnProperty('role') ? this.user.role : 'user'
    },
    ...mapGetters([
      'lastRowId',
      'currentSessionIndex'
    ])
  },

  methods: {
    ...mapActions([
      'createBasketItemLessonDefault',
      'createBasketItemSubscriptionDefault',
      'createBasketItemTicketDefault'
    ]),
    clickLessons () {
      this.createBasketItemLessonDefault()
        .then(() => {
          this.$onCommandParams('saleLessons', { id: this.lastRowId })
        })
    },
    clickSubscriptions () {
      this.createBasketItemSubscriptionDefault()
        .then(() => {
          this.$onCommandParams('saleSubscriptions', { id: this.lastRowId })
        })
    },
    clickCurrentSession () {
      if (this.currentSessionIndex !== -1) {
        this.createBasketItemTicketDefault({ sessionIdx: this.currentSessionIndex })
          .then(() => {
            this.$onCommandParams('saleTickets', { id: this.lastRowId })
          })
      }
    },
    clickEvents () {
      this.$onCommandParams('listOfEvents')
    },
    clickBooking () {
      this.$onCommandParams('booking')
    }
  }
}
</script>

<style lang="scss">

</style>
